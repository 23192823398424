// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('salesOrdersInvoice/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('salesOrdersInvoice/getData', async (params, { dispatch }) => {
  dispatch(setLoading({ getData: true }))
  const responseConsolidated = await axios.get('/SalesOrders/ConsolidatedList', { params })
  dispatch(setLoading({ getData: false }))
  return {
    params,
    consolidated: responseConsolidated.data
  }
})

export const getTypeData = createAsyncThunk('salesOrdersInvoice/getTypeData', async () => {
  // dispatch(setLoading({ getTypeData: true }))
  // const response = await axios.get('/JVTypes/List', { params })
  // dispatch(setLoading({ getTypeData: false }))
  // return {
  //   params,
  //   data: response.data
  // }
  return null
})

export const getJournal = createAsyncThunk('salesOrdersInvoice/getJournal', async () => {
  // dispatch(setLoading({ getJournal: true }))
  // const response = await axios.get(`/SalesInvoices/${recno}`)
  // dispatch(setLoading({ getJournal: false }))

  // return response.data
  return null
})

export const deleteInvoice = createAsyncThunk('salesOrdersInvoice/deleteInvoice', async (id, { dispatch, getState }) => {
  dispatch(setLoading({ deleteInvoice: true }))
  await axios.delete('/apps/invoice/delete', { id })
  await dispatch(getData(getState().invoice.params))
  dispatch(setLoading({ deleteInvoice: false }))
  return id
})

export const salesOrdersInvoiceSlice = createSlice({
  name: 'salesOrdersInvoice',
  initialState: {
    vendorInvoice: {},
    data: [],
    totalRecords: 1,
    consolidatedTotalRecords: 1,
    typeData: [],
    totalTypeRecords: 1,
    params: {},
    typeParams: {},
    loadingState: { getData: false, getTypeData: false, getJournal: false }
    // allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        // console.log(state, action)
        state.data = []
        state.consolidated = action.payload.consolidated.data.list
        state.totalRecords = 0
        state.consolidatedTotalRecords = action.payload.consolidated.totalRecords
        state.params = action.payload.params
      })
      .addCase(getTypeData.fulfilled, (state, action) => {
        state.typeData = action.payload.data.data.list
        state.totalTypeRecords = action.payload.data.totalRecords
        state.typeParams = action.payload.params
      })
      .addCase(getJournal.fulfilled, (state, action) => {
        state.journal = action.payload
      })
      .addCase(setLoading.fulfilled, (state, action) => {
        state.loadingState = action.payload
      })
  }
})

export default salesOrdersInvoiceSlice.reducer
