// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import {imageUrlToBase64} from "../../../../utility/Utils";

export const setLoading = createAsyncThunk('SaleInvoiceReturnCustomization/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('SaleInvoiceReturnCustomization/getData', async (params, { dispatch }) => {
    dispatch(setLoading({ getData: true }))
    const response = await axios.get('/InvoicePreviewCustomization/GetSalesReturnInvoiceCustomization', { params })
    dispatch(setLoading({ getData: false }))
    if(response.data.data.signatureImage) {
        try {
            response.data.data.signatureImageBase64 = await imageUrlToBase64(process.env.REACT_APP_API_URL_production + response.data.data.signatureImage);
        } catch(error) {
            console.log(error);
        }
    }


    if(response.data.data.stampImage) {
        try {
            response.data.data.stampImageBase64 = await imageUrlToBase64(process.env.REACT_APP_API_URL_production + response.data.data.stampImage);
        } catch(error) {
            console.log(error);
        }
    }

    if(response.data.data.showWaterImage) {
        try {
            response.data.data.showWaterImageBase64 = await imageUrlToBase64(process.env.REACT_APP_API_URL_production + response.data.data.showWaterImage);
        } catch(error) {
            console.log(error);
        }
    }

    return {
        params,
        data: response.data,

    }
})

export const getBank = createAsyncThunk('SaleInvoiceReturnCustomization/getBank', async (params, { dispatch }) => {
    dispatch(setLoading({ getData: true }))
    const response = await axios.get('/Locations/GetBankDetails', { params })
    dispatch(setLoading({ getData: false }))

    return {
        params,
        data: response.data,
    }
})

export const SaleInvoiceReturnCustomizationSlice = createSlice({
    name: 'SaleInvoiceReturnCustomization',
    initialState: {
        data: {},
        bank_data: {},
        params: {},
        loadingState: { getData: false }
        // allData: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data.data
                state.params = action.payload.params
            })
            .addCase(getBank.fulfilled, (state, action) => {
                state.bank_data = action.payload.data.data
                state.params = action.payload.params
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState = action.payload
            })
    }
})

export default SaleInvoiceReturnCustomizationSlice.reducer
