// ** Checks if an object is empty (returns boolean)
import {ToWords} from "to-words";
import base64url from "base64-url";
import queryString from "query-string";

export const isObjEmpty = obj => Object.keys(obj).length === 0
// ** Returns K format from a number
export const kFormatter = num => {
    if (num > 999 && num < 999999) {
        return `${(num / 1000).toFixed(1)}k`
    } else if (num > 999999 && num < 999999999) {
        return `${(num / 1000000).toFixed(1)}M`
    } else if (num > 999999999 && num < 999999999999) {
        return `${(num / 1000000000).toFixed(1)}B`
    } else if (num > 999999999) {
        return `${(num / 1000000000).toFixed(1)}T`
    } else if (num < -999 && num > -999999) {
        return `${(num / 1000).toFixed(1)}k`
    } else if (num < -999999 && num > -999999999) {
        return `${(num / 1000000).toFixed(1)}M`
    } else if (num < -999999999 && num > -999999999999) {
        return `${(num / 1000000000).toFixed(1)}B`
    } else if (num < -999999999) {
        return `${(num / 1000000000).toFixed(1)}T`
    } else {
        return (num > 999 || num < -999 ? `${(num / 1000).toFixed(1)}k` : num)
    }
}

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
    const today = new Date()
    return (
        /* eslint-disable operator-linebreak */
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
        /* eslint-enable */
    )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = {month: 'short', day: 'numeric', year: 'numeric'}) => {
    if (!value) return value
    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
    const date = new Date(value)
    let formatting = {month: 'short', day: 'numeric'}

    if (toTimeForCurrentDay && isToday(date)) {
        formatting = {hour: 'numeric', minute: 'numeric'}
    }

    return new Intl.DateTimeFormat('en-GB', formatting).format(new Date(value))
}

export const tablesDateFormat = 'mmm d, yyyy';

export const formatCurrency = (value = 0, defaultCurrency, showCurrency = true, roundingDigits = 2) => {
    let options = {};

    if (roundingDigits > -1) {
        options = {
            minimumFractionDigits: roundingDigits, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            maximumFractionDigits: roundingDigits, // (causes 2500.99 to be printed as $2,501)
        };
    }
    if (showCurrency) {
        options.style = 'currency';
        options.currency = `${defaultCurrency?.code !== "" ? defaultCurrency?.code ?? "USD" : "USD"}`;
        options.currencyDisplay = `code`;
    }

    let formatter = new Intl.NumberFormat('en-US', options);

    return formatter.format(value);
}

export const imageUrlToBase64 = async (url) => {
    try {
        const response = await fetch(url);
        const blob = await response.blob();

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    } catch (error) {
        console.error('Error converting image to base64:', error);
    }
}

export const inWords = (number, defaultCurrency) => {
    const toWords = new ToWords({
        localeCode: 'en-US',
        converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
            currencyOptions: { // can be used to override defaults for the selected locale
                name: defaultCurrency?.code,
                plural: defaultCurrency?.code,
                symbol: defaultCurrency.symbol,
                fractionalUnit: {
                    name: defaultCurrency.subUnit ?? "",
                    plural: defaultCurrency.subUnit ?? "",
                    symbol: '',
                },
            }
        }
    });
    return toWords.convert(number);
}

export const getTotal = (data) => {

    let total = 0;

    if ((data?.childs || []).length > 0) {
        data.childs.map(e => {
            let subTotal = 0;
            total += e.amount;
            subTotal += e.amount;
            if (e.childs) {
                total += getTotal(e)
                e.childAmount = subTotal - e.amount;
            }
        })
    }

    return total;
}

export const getTotalCompare = (data, column) => {

    let total = 0;

    if ((data?.childs || []).length > 0) {
        data.childs.map(e => {
            let subTotal = 0;
            total += e.dynamicColumns ? e.dynamicColumns[column] : 0;
            subTotal += e.dynamicColumns ? e.dynamicColumns[column] : 0;
            if (e.childs) {
                total += getTotalCompare(e, column)
            }
        })
    }

    return total;
}

export const collectChilds = (data, columns) => {
    let data_list = [];

    for (let i = 0; i < (data ?? []).length; i++) {
        let childs = data[i].childs ?? [];
        data[i].childs = undefined;
        let array = [];
        for (let j = 0; j < columns.length; j++) {
            array.push(data[i].dynamicColumns[columns[j]]);
        }
        const isAllZero = array.every(item => item === 0);
        const cChilds = collectChilds(childs, columns);
        if (!isAllZero || cChilds.length > 0) {
            data_list.push({...data[i]})
        }
        data_list.push(...cChilds);
    }

    return data_list;
}

export const getTotalTrialBalance = (data) => {
    let c_total_obdr = 0;
    let c_total_obcr = 0;
    let c_total_dr = 0;
    let c_total_cr = 0;
    let c_total_cbdr = 0;
    let c_total_cbcr = 0;
    for (let i = 0; i < data.childs.length; i++) {
        let {
            total_open_dr,
            total_open_cr,
            total_dr,
            total_cr,
            total_close_dr,
            total_close_cr
        } = (data.childs[i].childs || []).length > 0 ? getTotalTrialBalance(data.childs[i]) : {
            total_open_dr: 0,
            total_open_cr: 0,
            total_dr: 0,
            total_cr: 0,
            total_close_dr: 0,
            total_close_cr: 0
        };
        let closeBalance = (data.childs[i].obdr + data.childs[i].dr) - (data.childs[i].obcr + data.childs[i].cr);
        if (closeBalance >= 0) {
            data.childs[i].cbdr = closeBalance;
            data.childs[i].cbcr = 0;
        } else {
            data.childs[i].cbcr = -1 * closeBalance;
            data.childs[i].cbdr = 0;
        }
        data.childs[i].total_open_dr = (total_open_dr + data.childs[i].obdr);
        data.childs[i].total_open_cr = total_open_cr + data.childs[i].obcr;
        data.childs[i].total_dr = total_dr + data.childs[i].dr;
        data.childs[i].total_cr = total_cr + data.childs[i].cr;
        data.childs[i].total_close_dr = total_close_dr + data.childs[i].cbdr;
        data.childs[i].total_close_cr = total_close_cr + data.childs[i].cbcr;

        let totalCloseBalance = (data.childs[i].total_open_dr + data.childs[i].total_dr) - (data.childs[i].total_open_cr + data.childs[i].total_cr);

        if (totalCloseBalance >= 0) {
            data.childs[i].total_close_dr = totalCloseBalance;
            data.childs[i].total_close_cr = 0;
        } else {
            data.childs[i].total_close_cr = -1 * totalCloseBalance;
            data.childs[i].total_close_dr = 0;
        }

        c_total_obdr += data.childs[i].total_open_dr;
        c_total_obcr += data.childs[i].total_open_cr;
        c_total_dr += data.childs[i].total_dr;
        c_total_cr += data.childs[i].total_cr;
        c_total_cbdr += data.childs[i].total_close_dr;
        c_total_cbcr += data.childs[i].total_close_cr;
    }
    data.total_open_dr = c_total_obdr;
    data.total_open_cr = c_total_obcr;
    data.total_dr = c_total_dr;
    data.total_cr = c_total_cr;
    data.total_close_dr = c_total_cbdr;
    data.total_close_cr = c_total_cbcr;
    return data;
}
/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
    if (userRole === 'admin') return '/'
    if (userRole === 'client') return '/'// '/access-control'
    // return '/login'
    return '/'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: '#1F20541a', // for option hover bg-color
        primary: '#1F2054', // for selected option bg-color
        neutral10: '#2d2e8f', // for tags bg-color
        neutral20: '#ededed', // for input border-color
        neutral30: '#ededed' // for input hover border-color
    }
})

export const conditionalRowStylesForms = [
    {
        when: (row) => row.isDeleted,
        style: {
            display: 'none',
        },
    },
];


export const debounce = (callback, timeout = 300) => {
    let timer
    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            callback(...args)
        }, timeout)
    }
}

export function asyncDebounce(inner, ms = 0) {
    let timer = null
    let resolves = []

    return function (...args) {
        // Run the function after a certain amount of time
        clearTimeout(timer)
        timer = setTimeout(() => {
            // Get the result of the inner function, then apply it to the resolve function of
            // each promise that has been created since the last time the inner function was run
            const result = inner(...args)
            resolves.forEach(r => r(result))
            resolves = []
        }, ms)

        return new Promise(r => resolves.push(r))
    }
}

export const getFingerprint = (callback) => {
    const RTCPeerConnection = window.webkitRTCPeerConnection || window.mozRTCPeerConnection

    const addrs = Object.create(null)
    addrs["0.0.0.0"] = false

    const fingerprint = () => {
        const rtc = new RTCPeerConnection({iceServers: []})
        if (1 || window.mozRTCPeerConnection) {
            rtc.createDataChannel('', {reliable: false})
        }
        const updateDisplay = (newAddr) => {
            if (!(newAddr in addrs)) addrs[newAddr] = true
        }

        const grepSDP = (sdp) => {
            sdp.split('\r\n').forEach((line) => {
                if (~line.indexOf("a=candidate")) {
                    const parts = line.split(' '),
                        addr = parts[4],
                        type = parts[7]
                    if (type === 'host') updateDisplay(addr)
                } else if (~line.indexOf("c=")) {
                    const parts = line.split(' '),
                        addr = parts[2]
                    updateDisplay(addr)
                }
            })
            const displayAddrs = Object.keys(addrs).filter((k) => addrs[k])
            callback(displayAddrs)
        }

        rtc.onicecandidate = (evt) => evt.candidate && grepSDP("a=".concat(evt.candidate.candidate))

        rtc.createOffer((offerDesc) => {
            grepSDP(offerDesc.sdp)
            rtc.setLocalDescription(offerDesc)
        }, (e) => {
            console.warn("offer failed", e)
        })
    }


    if (RTCPeerConnection) {
        fingerprint()
    } else {
        callback([])
    }
}

const Base64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = Buffer.from(b64Data, 'base64')
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, {type: contentType})
    return blob
}

export const filterConfigurationList = (listName, listsConfigurations) => {
    return listsConfigurations?.filter((item) => item.listName === listName)?.map(item => {
        return {
            ...item,
            columnName: item.columnName[0].toLowerCase() + item.columnName.slice(1, item.columnName.length)
        }
    }) || []
}

export const checkAndSetNumVal = (val, old, maxFloat, key = null) => {

    let value = val.target.value
    console.log(value.includes('-'), value !== "", value)
    if (value !== "") {
        if (isNaN(value)) {
            value = old
        } else if (value.includes('-')) {
            value = ""
        } else if (value.includes('.')) {
            value = parseFloat((parseFloat(value)).toFixed(maxFloat))
        }
    }

    // value = isNaN(parseFloat(value))?''
    return key ? {[key]: value} : {target: {name: val.target.name, value}}

}

export const checkAndSetNumValPR = (val, old, maxFloat, allowNegative, key = null) => {

    let value = val.target.value
    console.log(value.includes('-'), value !== "", value)
    if (value !== "") {
        if (isNaN(value)) {
            value = old
        } else if (value.includes('-') && !allowNegative) {
            value = ""
        } else if (value.includes('.')) {
            value = parseFloat((parseFloat(value)).toFixed(maxFloat))
        }
    }

    // value = isNaN(parseFloat(value))?''
    return key ? {[key]: value} : {target: {name: val.target.name, value}}

}

const cvalue = (value) => {
    return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
};

export const calculateValuesAndTax = (invoice, tempDetail, roundingDigit) => {
    const exAmount = cvalue(tempDetail.qty) * cvalue(tempDetail.transactionCurrencyRate);
    const totalAmount = cvalue(tempDetail.qty) * cvalue(tempDetail.transactionCurrencyRate);
    tempDetail.exAmount = cvalue((exAmount ?? 0));
    tempDetail.discountAmount = tempDetail.discountType === "" || tempDetail.discountType === "%" || tempDetail.discountType === "DT" ? exAmount * (cvalue(tempDetail.discountVal) / 100) : (tempDetail.qty) * cvalue(tempDetail.discountVal);
    tempDetail.discountAmount = cvalue(tempDetail.discountAmount);
    tempDetail.totalAmount = totalAmount;
    tempDetail.exAmount = cvalue((tempDetail.exAmount - tempDetail.discountAmount));

    const taxPer = tempDetail.taxPer !== null ? tempDetail.taxPer : invoice.taxPer;
    if (invoice.invoiceTaxType === "Exclusive") {

        if (taxPer) {
            tempDetail.taxAmount = cvalue(cvalue(tempDetail.exAmount) * (taxPer / 100));
        } else {
            tempDetail.taxAmount = 0;
        }
        tempDetail.exAmount = cvalue(tempDetail.exAmount);
        tempDetail.inAmount = cvalue((parseFloat(tempDetail.exAmount) + parseFloat((tempDetail.taxAmount ?? 0))));
    } else if (invoice.invoiceTaxType === "OutOfScope") {
        tempDetail.taxType = null;
        tempDetail.taxPer = 0;
        tempDetail.taxText = "";
        tempDetail.taxAmount = 0;
        tempDetail.inAmount = cvalue((parseFloat(tempDetail.exAmount) + parseFloat((tempDetail.taxAmount ?? 0))));
    } else {
        if (taxPer) {
            tempDetail.taxAmount = cvalue((cvalue(tempDetail.exAmount) / (taxPer + 100)) * taxPer);
        } else {
            tempDetail.taxAmount = 0;
        }
        tempDetail.inAmount = cvalue(tempDetail.exAmount);
        tempDetail.exAmount = cvalue((tempDetail.exAmount - tempDetail.taxAmount));
    }

    return tempDetail;
}

export const generateTrailBalance = (trail_balance, level) => {
    let result;
    if (level === 5)
        result = groupBy(trail_balance, (c) => c.l5recno);
    else if (level === 4)
        result = groupBy(trail_balance, (c) => c.l4recno);
    else if (level === 3)
        result = groupBy(trail_balance, (c) => c.l3recno);
    else if (level === 2)
        result = groupBy(trail_balance, (c) => c.l2recno);
    else if (level === 1)
        result = groupBy(trail_balance, (c) => c.l1recno);
    else
        result = [];
    return result;
}

export const generateTrailBalanceData = (trail_balance, levelsAvailability) => {
    let resultGenerated = [];
    let result = generateTrailBalance(trail_balance, 1);
    Object.keys(result).forEach(function (key, index) {
        let data = {
            key: key,
            Level_Name: "",
            Level_Code: "",
            total_open_dr: 0,
            total_open_cr: 0,
            dr: 0,
            cr: 0,
            total_close_dr: 0,
            total_close_cr: 0,
        }

        let totalOpenDr = 0;
        let totalOpenCr = 0;
        let totalCloseDr = 0;
        let totalCloseCr = 0;
        let totalDr = 0;
        let totalCr = 0;

        result[key].map((groupedItems, i) => {
            data.Level_Name = groupedItems.Level_1_Name;
            data.Level_Code = groupedItems.Level_1_Code;
            data.total_open_dr = groupedItems.obdr;
            data.total_open_cr = groupedItems.obcr;
            data.dr = groupedItems.dr;
            data.cr = groupedItems.cr;
            result[key][i].cbdr = groupedItems.obdr + groupedItems.dr;
            result[key][i].cbcr = groupedItems.obcr + groupedItems.cr;

            if (result[key][i].cbdr - result[key][i].cbcr > 0) {
                result[key][i].cbdr = result[key][i].cbdr - result[key][i].cbcr;
                result[key][i].cbcr = 0;
            } else {
                result[key][i].cbcr = (result[key][i].cbdr - result[key][i].cbcr) * -1;
                result[key][i].cbdr = 0;
            }

            totalOpenDr += groupedItems.obdr;
            totalOpenCr += groupedItems.obcr;
            let valueClose = (groupedItems.obdr + groupedItems.dr) - (groupedItems.obcr + groupedItems.cr);
            totalCloseDr += (valueClose > 0 ? valueClose : 0);
            totalCloseCr += (valueClose >= 0 ? 0 : (valueClose * -1));
            totalDr += groupedItems.dr;
            totalCr += groupedItems.cr;
        });

        let resultGenerated2 = [];
        if (levelsAvailability.l2 && levelsAvailability.l3) {
            let result2 = generateTrailBalance(result[key], 2);
            Object.keys(result2).forEach(function (key2, index2) {
                let data2 = {
                    key: key2,
                    Level_Name: "",
                    Level_Code: "",
                    total_open_dr: 0,
                    total_open_cr: 0,
                    dr: 0,
                    cr: 0,
                    total_close_dr: 0,
                    total_close_cr: 0,
                }

                let totalOpenDr2 = 0;
                let totalOpenCr2 = 0;
                let totalCloseDr2 = 0;
                let totalCloseCr2 = 0;
                let totalDr2 = 0;
                let totalCr2 = 0;

                result2[key2].map((groupedItems, i) => {
                    data2.Level_Name = groupedItems.Level_2_Name;
                    data2.Level_Code = groupedItems.Level_2_Code;
                    data2.total_open_dr = groupedItems.obdr;
                    data2.total_open_cr = groupedItems.obcr;
                    data2.dr = groupedItems.dr;
                    data2.cr = groupedItems.cr;
                    result2[key2][i].cbdr = groupedItems.obdr + groupedItems.dr;
                    result2[key2][i].cbcr = groupedItems.obcr + groupedItems.cr;
                    if (result2[key2][i].cbdr - result2[key2][i].cbcr > 0) {
                        result2[key2][i].cbdr = result2[key2][i].cbdr - result2[key2][i].cbcr;
                        result2[key2][i].cbcr = 0;
                    } else {
                        result2[key2][i].cbcr = (result2[key2][i].cbdr - result2[key2][i].cbcr) * -1;
                        result2[key2][i].cbdr = 0;
                    }
                    totalOpenDr2 += groupedItems.obdr;
                    totalOpenCr2 += groupedItems.obcr;
                    let valueClose = (groupedItems.obdr + groupedItems.dr) - (groupedItems.obcr + groupedItems.cr);
                    totalCloseDr2 += (valueClose >= 0 ? valueClose : 0);
                    totalCloseCr2 += (valueClose >= 0 ? 0 : (valueClose * -1));
                    totalDr2 += groupedItems.dr;
                    totalCr2 += groupedItems.cr;
                });

                let resultGenerated3 = [];

                if (levelsAvailability.l3 && levelsAvailability.l4) {
                    let result3 = generateTrailBalance(result2[key2], 3);
                    Object.keys(result3).forEach(function (key3, index2) {
                        let data3 = {
                            key: key3,
                            Level_Code: "",
                            Level_Name: "",
                            total_open_dr: 0,
                            total_open_cr: 0,
                            dr: 0,
                            cr: 0,
                            total_close_dr: 0,
                            total_close_cr: 0,
                        }

                        let totalOpenDr3 = 0;
                        let totalOpenCr3 = 0;
                        let totalCloseDr3 = 0;
                        let totalCloseCr3 = 0;
                        let totalDr3 = 0;
                        let totalCr3 = 0;

                        result3[key3].map((groupedItems, i) => {
                            data3.Level_Name = groupedItems.Level_3_Name;
                            data3.Level_Code = groupedItems.Level_3_Code;
                            data3.total_open_dr = groupedItems.obdr;
                            data3.total_open_cr = groupedItems.obcr;
                            data3.dr = groupedItems.dr;
                            data3.cr = groupedItems.cr;
                            result3[key3][i].cbdr = groupedItems.obdr + groupedItems.dr;
                            result3[key3][i].cbcr = groupedItems.obcr + groupedItems.cr;
                            if (result3[key3][i].cbdr - result3[key3][i].cbcr > 0) {
                                result3[key3][i].cbdr = result3[key3][i].cbdr - result3[key3][i].cbcr;
                                result3[key3][i].cbcr = 0;
                            } else {
                                result3[key3][i].cbcr = (result3[key3][i].cbdr - result3[key3][i].cbcr) * -1;
                                result3[key3][i].cbdr = 0;
                            }
                            totalOpenDr3 += groupedItems.obdr;
                            totalOpenCr3 += groupedItems.obcr;
                            let valueClose = (groupedItems.obdr + groupedItems.dr) - (groupedItems.obcr + groupedItems.cr);
                            totalCloseDr3 += (valueClose >= 0 ? valueClose : 0);
                            totalCloseCr3 += (valueClose >= 0 ? 0 : (valueClose * -1));
                            totalDr3 += groupedItems.dr;
                            totalCr3 += groupedItems.cr;
                        });

                        data3.total_open_dr = totalOpenDr3;
                        data3.total_open_cr = totalOpenCr3;
                        data3.total_close_dr = totalCloseDr3;
                        data3.total_close_cr = totalCloseCr3;
                        data3.dr = totalDr3;
                        data3.cr = totalCr3;
                        data3.children = result3[key3];
                        resultGenerated3.push(data3);
                    })
                }

                data2.total_open_dr = totalOpenDr2;
                data2.total_open_cr = totalOpenCr2;
                data2.total_close_dr = totalCloseDr2;
                data2.total_close_cr = totalCloseCr2;
                data2.dr = totalDr2;
                data2.cr = totalCr2;
                if (levelsAvailability.l3 && levelsAvailability.l4) {
                    data2.child = resultGenerated3;
                } else {
                    data2.children = result2[key2];
                }
                resultGenerated2.push(data2);
            })
        }

        data.total_open_dr = totalOpenDr;
        data.total_open_cr = totalOpenCr;
        data.total_close_dr = totalCloseDr;
        data.total_close_cr = totalCloseCr;
        data.dr = totalDr;
        data.cr = totalCr;
        if (levelsAvailability.l2 && levelsAvailability.l3) {
            data.child = resultGenerated2;
        } else {
            data.children = result[key];
        }
        resultGenerated.push(data);
    });
    return resultGenerated;
}

export const groupBy = function (xs, key) {
    console.log(xs, key, "<><><><><>")
    return xs?.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const getDateFromTerm = (term = "", invDate = new Date()) => {
    let dueDate = new Date(new Date(invDate).toUTCString());
    
    if (term.toLowerCase() === "due on receipt") {
        dueDate.setDate(dueDate.getDate());
    } else if (term.toLowerCase() === "net 15") {
        dueDate.setDate(dueDate.getDate() + 15);
    } else if (term.toLowerCase() === "net 30") {
        dueDate.setDate(dueDate.getDate() + 30);
    } else if (term.toLowerCase() === "net 45") {
        dueDate.setDate(dueDate.getDate() + 45);
    } else if (term.toLowerCase() === "net 60") {
        dueDate.setDate(dueDate.getDate() + 60);

    } else if (term.toLowerCase() === "due end of the month") {
        dueDate = new Date(dueDate.getFullYear(), dueDate.getMonth() + 1, 0);
    } else if (term.toLowerCase() === "due end of next month") {
        dueDate = new Date(dueDate.getFullYear(), dueDate.getMonth() + 2, 0);
    } else {
        let days = 0;
        try {
            days = parseInt(term);
        } catch (e) {
            days = 0;
        }
        dueDate.setDate(dueDate.getDate() + (isNaN(days) ? 0 : days));
    }

    return new Date(parseInt(`${Date.UTC(dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate())}`));
}

export const getTransactionalRate = (base_rate, conversionRate, roundingDigit = 2) => {
    if (!base_rate) {
        base_rate = 0;
    }

    if (!conversionRate || conversionRate === 0) {
        conversionRate = 1;
    }

    return parseFloat((base_rate / conversionRate))
}

export const printPageStyle = `
  @media print {
  .table-blue-footer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
  }

  body {
    position: relative;
    zoom: 1.3;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: visible;
  }

  #footer {
      page-break-after: always !important;
      position: fixed !important;
      bottom: 0px !important;
      left: 0px !important;
      width: 100% !important;
  }

  @page {
    marginLeft: 0.5mm;
    marginRight: 0.5mm;
    marginTop: 0mm;
    marginBottom: 0mm;
  }

  header,
  footer {
    display: none !important;
  }
}`;

export const printPageStyleTable = `
  @media print {
  .table-blue-footer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
  }

  body {
    position: relative;
    zoom: 0.6;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: visible;
  }

  #footer {
      page-break-after: always !important;
      position: fixed !important;
      bottom: 0px !important;
      left: 0px !important;
      width: 100% !important;
  }

  @page {
    marginLeft: 0.5mm;
    marginRight: 0.5mm;
    marginTop: 0mm;
    marginBottom: 0mm;
  }

  header,
  footer {
    display: none !important;
  }
}`;

export const printPage80Style = (pageHeight) => { return `
  @media print {
  width: 80mm;
  height: ${pageHeight * 10 + 100}mm;
  .table-blue-footer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
  } 
  
  body {
    position: relative;
    zoom: 1.3;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: visible;
  }
  
  #footer {
      page-break-after: always !important;
      position: fixed !important;
      bottom: 0px !important;
      left: 0px !important;
      width: 100% !important;
  }
  
  @page {
    marginLeft: 0.5mm;
    marginRight: 0.5mm;
    marginTop: 0mm;
    marginBottom: 0mm;
  }
  
  header,
  footer {
    display: none !important;
  }
}`
};


export const convertFoldersToArray = (array = []) => {
    let converted_array = [];

    for(let i = 0 ; i < array.length; i++){
        let tempObj = {...array[i]};
        let subFolders = tempObj.subFolders??[];
        tempObj.subFolders = undefined;
        converted_array.push(tempObj);

        if(subFolders.length > 0){
            converted_array.push(...(convertFoldersToArray(subFolders)))
        }
    }

    return converted_array;
}

export const encodeParameters = (parameters) => {
    return base64url.encode((queryString.stringify(parameters)))
}

export const decodeParameters = (encoded) => {
    let decodedParams = base64url.decode(encoded);
    return queryString.parse(decodedParams);
}

export const showTax = (userData, date) => {
    return userData.isTaxRegistered || new Date(date??(new Date())).getTime() <= new Date((userData?.taxRegistrationDate??'1990-01-01T00:00:00').replace('T00:00:00', 'T23:59:59')).getTime();
}

export const checkApprovalStatus = (approval_data, invoiceDetail, type) => {

    let status = true

    if(type.toLowerCase() === 'customerinvoice'){
       if(approval_data.isSalesApprovalLvl1RightsEnabled){
           if(!invoiceDetail[0].lvl1){
               status = false
           }
       }
       if(approval_data.isSalesApprovalLvl2RightsEnabled){
           if(!invoiceDetail[0].lvl2){
               status = false
           }
       }
       if(approval_data.isSalesApprovalLvl3RightsEnabled){
           if(!invoiceDetail[0].lvl3){
               status = false
           }
       }
       if(approval_data.isSalesApprovalLvl4RightsEnabled){
           if(!invoiceDetail[0].lvl4){
               status = false
           }
       }
    }

    return status
}

export const arabicDayNames = [
    "الأحد",
    "الإثنين",
    "الثلاثاء",
    "الأربعاء",
    "الخميس",
    "الجمعة",
    "السبت",
    "الأحد",
    "الإثنين",
    "الثلاثاء",
    "الأربعاء",
    "الخميس",
    "الجمعة",
    "السبت",
];

export const arabicMonthNames = [
    "يناير",
    "فبراير",
    "مارس",
    "أبريل",
    "مايو",
    "يونيو",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
    "يناير",
    "فبراير",
    "مارس",
    "أبريل",
    "مايو",
    "يونيو",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
];

export const englishDayNames = [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];

export const englishMonthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];