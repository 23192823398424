// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import {getPurchaseActivityLogData} from "../../../purchases/vendor-invoice/store";

export const setLoading = createAsyncThunk('appCustomerInvoice/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('appCustomerInvoice/getData', async (params, { dispatch, getState }) => {
  dispatch(setLoading({ getData: true }))
  if (JSON.stringify(params) !== JSON.stringify(getState().customerInvoice?.params) || params.forceLoad) {
    try {
      if(!params.PageNo) {
        params.PageNo = getState().customerInvoice?.params.Page??1;
      }

      if(!params.PageSize) {
        params.PageSize = getState().customerInvoice?.params.PageSize??20;
      }

      const responseConsolidated = await axios.get('/SalesInvoices/ConsolidatedList', {params})
      dispatch(setLoading({getData: false}))
      return {
        params,
        data: {data: {list: [], totalRecords: 0}},
        consolidated: responseConsolidated.data
      }
    } catch (e) {
      dispatch(setLoading({getData: false}))
      return {
        params,
        data: {data: {list: [], totalRecords: 0}},
        consolidated: {data: {list: [], totalRecords: 0}}
      }
    }
  } else {
    dispatch(setLoading({getData: false}))
    return {
      params: getState().customerInvoice?.params,
      data: {data: {list: getState().customerInvoice?.data??[], totalRecords: getState().customerInvoice?.totalRecords??0}},
      consolidated: {data: {list: getState().customerInvoice?.consolidated??[], totalRecords: getState().customerInvoice?.consolidatedTotalRecords??0}}
    }
  }
})

export const getInvoiceActivityLogData = createAsyncThunk('appPurchase/getInvoiceActivityLogData', async (recno, { dispatch }) => {
  dispatch(setLoading({ getInvoiceActivityLogData: true }))
  const response = await axios.get(`/SalesInvoices/GetSalesLog/${recno}`)
  dispatch(setLoading({ getInvoiceActivityLogData: false }))
  return {
    data: response.data
  }
})

export const getReportingTags = createAsyncThunk('appPurchase/getReportingTags', async ( { dispatch }) => {
  dispatch(setLoading({ getReportingTags: true }))
  const response = await axios.get(`/Defaults/GetReportingTags`)
  dispatch(setLoading({ getReportingTags: false }))
  return {
    data: response.data
  }
})

export const getTypeData = createAsyncThunk('appCustomerInvoice/getTypeData', async () => {
  // dispatch(setLoading({ getTypeData: true }))
  // const response = await axios.get('/JVTypes/List', { params })
  // dispatch(setLoading({ getTypeData: false }))
  // return {
  //   params,
  //   data: response.data
  // }
  return null
})

export const getJournal = createAsyncThunk('appCustomerInvoice/getJournal', async () => {
  // dispatch(setLoading({ getJournal: true }))
  // const response = await axios.get(`/SalesInvoices/${recno}`)
  // dispatch(setLoading({ getJournal: false }))

  // return response.data
  return null
})

export const deleteInvoice = createAsyncThunk('appCustomerInvoice/deleteInvoice', async (id, { dispatch, getState }) => {
  dispatch(setLoading({ deleteInvoice: true }))
  await axios.delete('/apps/invoice/delete', { id })
  await dispatch(getData(getState().invoice.params))
  dispatch(setLoading({ deleteInvoice: false }))
  return id
})

export const appCustomerInvoiceSlice = createSlice({
  name: 'appCustomerInvoice',
  initialState: {
    vendorInvoice: {},
    data: [],
    consolidated: [],
    totalRecords: 1,
    consolidatedTotalRecords: 1,
    activityLogs: [],
    reportingTags: [],
    totalLogsRecords: 1,
    typeData: [],
    totalTypeRecords: 1,
    params: {},
    typeParams: {},
    loadingState: { getData: false, getTypeData: false, getJournal: false,getReportingTags: false }
    // allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data.data.list
        state.consolidated = action.payload.consolidated?.data?.list??[]
        state.totalRecords = action.payload.data.totalRecords
        state.consolidatedTotalRecords = action.payload.consolidated.totalRecords
        state.params = action.payload.params
      })
      .addCase(getInvoiceActivityLogData.fulfilled, (state, action) => {
        state.activityLogs = action.payload.data.data.list
        state.totalLogsRecords = action.payload.data.totalRecords
      })
      .addCase(getReportingTags.fulfilled, (state, action) => {
        state.reportingTags = action.payload.data.data
      })
      .addCase(getTypeData.fulfilled, (state, action) => {
        state.typeData = action.payload.data.data.list
        state.totalTypeRecords = action.payload.data.totalRecords
        state.typeParams = action.payload.params
      })
      .addCase(getJournal.fulfilled, (state, action) => {
        state.journal = action.payload
      })
      .addCase(setLoading.fulfilled, (state, action) => {
        state.loadingState = action.payload
      })
  }
})

export default appCustomerInvoiceSlice.reducer
